import { useState } from "react";
import Layout from "Layout";
import PaymentTableHeader from "components/TableHeaders/PaymentTableHeader";
import CustomTable from "components/Table/CustomTable";
import paymentItemsData, { columns } from "./helpers";
import CreatePaymentItemsForm from "screens/Payments/CreatePaymentItemsForm";

function PaymentItemsTable() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openNewItemModal = () => {
    setIsModalOpen(true);
  };

  const closeNewItemModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <PaymentTableHeader openNewItemModal={openNewItemModal} />
      {isModalOpen && <CreatePaymentItemsForm onClose={closeNewItemModal} />}

      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="10"
        data-aos-offset="200"
        className="my-8 rounded-xl border-[1px] border-border"
      >
        <div className="w-full overflow-x-scroll bg-white p-5">
          <CustomTable
            data={paymentItemsData}
            columns={columns}
            // actions={actions(previewPayment)}
          />
        </div>
      </div>
    </Layout>
  );
}

export default PaymentItemsTable;
