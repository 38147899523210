import Layout from "Layout";
// import { BiPlus } from "react-icons/bi";

import CustomTable from "components/Table/CustomTable";
import paymentData, { columns } from "./helpers";
import WebPayTableHeader from "components/TableHeaders/WebPayTableHeader";

function WebPay() {
  // const previewPayment = (id: number) => {
  //   navigate(`/patients/preview/${id}`);
  // };

  return (
    <Layout>
      {/* <TableHeader /> */}
      <WebPayTableHeader />
      {/* <Link
        to="/patients/create"
        className="w-16 animate-bounce h-16 border border-border z-50 bg-subMain text-white rounded-full flex-colo fixed bottom-8 right-12 button-fb"
      >
        <BiPlus className="text-2xl" />
      </Link> */}

      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="10"
        data-aos-offset="200"
        className="my-8 rounded-xl border-[1px] border-border"
      >
        <div className="w-full overflow-x-scroll bg-white p-5">
          <CustomTable
            data={paymentData}
            columns={columns}
            // actions={actions(previewPayment)}
          />
        </div>
      </div>
    </Layout>
  );
}

export default WebPay;
