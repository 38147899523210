import Layout from "Layout";

import CustomTable from "components/Table/CustomTable";
import paymentData, { paymentItemColumns } from "./WebPay/helpers";

function BankChannel() {
  return (
    <Layout>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="10"
        data-aos-offset="200"
        className="bg-white my-8 rounded-xl border-[1px] border-border p-5"
      >
        <div className="w-full overflow-x-scroll">
          <CustomTable data={paymentData} columns={paymentItemColumns} />
        </div>
      </div>
    </Layout>
  );
}

export default BankChannel;
