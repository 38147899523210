import axios from "axios";
import { SCHOOL_ENDPOINT } from ".";

export const executeGetSchools = async (): Promise<ResponseDataType> => {
  try {
    const options = {
      url: SCHOOL_ENDPOINT,
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    console.log({ response });
    return response.data.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};
