import { useAppSelector } from "hooks/redux";
import { MenuDatas } from "../components/Datas";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";
import { BiChevronDown } from "react-icons/bi";

function Sidebar() {
  const permissions = useAppSelector((state) => state.account.dashboardModules);
  const selectedSchool = useAppSelector((state) => state.school.selectedSchool);

  console.log({ selectedSchool });

  const notificationTitleMap: {
    [key: string]: string;
  } = {
    "Successful Notifications": "Successful",
    "Failed Notifications": "Failed",
    "All Notifications": "All",
    Support: "Support",
    Students: "Students",
    "Payment items": "Payment Items",
  };

  const filterMenuItems = () => {
    const formattedPermissions = permissions.map((item) => item.toLowerCase());

    const menuWithAllDropdowns = MenuDatas.filter(
      (item) =>
        formattedPermissions.includes(item.title.toLowerCase()) ||
        (item.children && item.children.length > 0)
    );

    const menu = menuWithAllDropdowns.map((menuItem) => {
      if (!menuItem.children) return menuItem;

      const result = menuItem.children.filter((child) => {
        const mappedTitle = Object.keys(notificationTitleMap).find(
          (key) =>
            notificationTitleMap[key].toLowerCase() ===
            child.title.toLowerCase()
        );

        return (
          !!mappedTitle &&
          formattedPermissions.includes(mappedTitle.toLowerCase())
        );
      });

      return {
        ...menuItem,
        children: result,
      };
    });

    return menu;
  };

  const currentPath = (path: string) => {
    const currentPath =
      window.location.pathname.split("/")[1] === path.split("/")[1];
    if (currentPath) {
      return path;
    }
    return null;
  };

  return (
    <div className="bg-white xl:shadow-lg py-6 px-4 xl:h-screen w-full border-r border-border">
      <Link to="/">
        <img
          src={selectedSchool?.logo}
          alt="logo"
          className="w-2/4 h-12 ml-4 object-contain"
        />
      </Link>
      <div className="flex flex-col gap-4 mt-12">
        {filterMenuItems().map((item, index) => {
          if (!item?.children) {
            return (
              <Link
                to={item.path}
                key={index}
                className={`
            ${currentPath(item.path) === item.path ? "bg-subMain" : ""}
            flex gap-4 transitions group items-center w-full p-4 rounded-lg`}
              >
                {!!item.icon && (
                  <item.icon
                    color={
                      currentPath(item.path) === item.path ? "white" : "#737791"
                    }
                    size={29}
                  />
                )}
                <p
                  className={`text-[14px] font-medium ${
                    currentPath(item.path) === item.path
                      ? "text-white"
                      : "text-text"
                  }`}
                >
                  {item.title}
                </p>
              </Link>
            );
          } else if (item.children.length > 0) {
            return (
              <Collapsible
                trigger={
                  <div className="flex justify-between py-4 pl-2 pr-8">
                    <span className="font-bold text-lg">{item.title}</span>
                    <BiChevronDown size={"20px"} />
                  </div>
                }
              >
                {item.children.map((subItem, idx) => (
                  <Link
                    to={subItem.path}
                    key={idx}
                    className={`
            ${currentPath(subItem.path) === subItem.path ? "bg-subMain" : ""}
            flex gap-4 transitions group items-center w-full p-4 rounded-lg`}
                  >
                    {!!subItem.icon && (
                      <subItem.icon
                        color={
                          currentPath(subItem.path) === subItem.path
                            ? "white"
                            : "#737791"
                        }
                        size={29}
                      />
                    )}
                    <p
                      className={`text-[14px] font-medium ${
                        currentPath(subItem.path) === subItem.path
                          ? "text-white"
                          : "text-text"
                      }`}
                    >
                      {subItem.title}
                    </p>
                  </Link>
                ))}
              </Collapsible>
            );
          }
          return null; // Add this line to handle empty children case
        })}
      </div>
    </div>
  );
}

export default Sidebar;
