import { CiCirclePlus, CiUndo } from "react-icons/ci";


interface StudentTableHeaderProps {
  openNewItemModal: () => void; 
}

const StudentTableHeader = ({ openNewItemModal }: StudentTableHeaderProps) => {
  return (
    <div className="flex justify-between">
      <div className="flex gap-4">
        <input
          type="search"
          className="border py-2 px-4 text-sm border-[#D5D5D5] rounded-3xl"
          placeholder="Search keywords"
        />
        <button
          className="flex font-semibold items-center gap-2 border rounded-3xl py-2 px-4 text-sm border-[#D5D5D5] text-[#202224]"
          onClick={() => openNewItemModal()}
        >
          <CiCirclePlus size={18} />
          New
        </button>
        <button className="flex items-center font-semibold gap-2 text-[#EA0234] rounded-3xl border py-2 px-4 text-sm border-[#D5D5D5]">
          <CiUndo size={18} />
          Reset
        </button>
      </div>
    </div>
  );
}


export default StudentTableHeader;

