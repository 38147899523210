import axios from "axios";
import {
  GET_STUDENTS_ENDPOINT,
  GET_PATIENTS_IN_HOSPITAL_ENDPOINT,
  GET_PATIENT_ENDPOINT,
  GET_PATIENT_INVOICES_ENDPOINT,
  REGISTER_PATIENT_ENDPOINT,
  UPDATE_PATIENT_STATUS_ENDPOINT,
  REGISTER_MULTIPLE_USERS_ENDPOINT,
  CREATE_PAYMENT_ITEMS,
  GET_ALL_INVOICES,
} from ".";

export const executeGetStudentsBySchool = async ({
  queryKey,
}: {
  queryKey: [string, number];
}) => {
  const [, id] = queryKey;
  const response = await axios.get(`${GET_STUDENTS_ENDPOINT(id)}`);
  return response.data.data.data;
};

export const executeGetPatientById = async (
  id: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: GET_PATIENT_ENDPOINT(id),
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    return {
      message: response.data.message,
      status: "error",
      data: response.data.data,
      isSuccess: true,
    };
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeGetPatientInvoices = async (
  id: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: GET_PATIENT_INVOICES_ENDPOINT(id),
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    return {
      message: response.data.message,
      status: "error",
      data: response.data.data,
      isSuccess: true,
    };
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeUpdatePatientStatus = async (
  payload: any,
  token: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: UPDATE_PATIENT_STATUS_ENDPOINT,
      data: payload,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    return {
      message: response.data.message,
      status: "error",
      data: response.data.data,
      isSuccess: true,
    };
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeGetPatientsInHospital = async (
  hospitalId: number,
  token: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: GET_PATIENTS_IN_HOSPITAL_ENDPOINT(hospitalId),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeRegisterPatient = async (
  data: FormData,
  token: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: REGISTER_PATIENT_ENDPOINT,
      data: data,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data;
    } else {
      return { message: error.message, status: "error" } as ResponseDataType;
    }
  }
};

export const executeRegisterMultipleStudent = async (
  data: any
): Promise<ResponseDataType> => {
  try {
    const options = {
      method: "POST",
      url: REGISTER_MULTIPLE_USERS_ENDPOINT,
      data: data,
    };

    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    return {
      message: error.response.data.message,
      isSuccess: false,
      status: error,
    };
  }
};

export const executeCreatePaymentItem = async (
  data: any
): Promise<ResponseDataType> => {
  try {
    const options = {
      method: "POST",
      url: CREATE_PAYMENT_ITEMS,
      data: data,
    };

    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    return {
      message: error.response.data.message,
      isSuccess: false,
      status: error,
    };
  }
};

export const executeGetInvoices = async (
  id: number
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: GET_ALL_INVOICES(id),
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    console.log({ response });
    return response.data.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};
