const BASE_URL = "https://api.payprosolutionsltd.com";

// const BASE_URL = process.env.REACT_APP_API_URL;

// AUTH ENDPOINTS
export const AUTH_ENDPOINTS = {
  REGISTER_ENDPOINT: `${BASE_URL}/register-user`,
  SET_PASSWORD_ENDPOINT: `${BASE_URL}/set-password`,
  LOGIN_ENDPOINT: `${BASE_URL}/login`,
  VERIFY_2FA: `${BASE_URL}/verify-2fa`,
  SETUP_2FA: `${BASE_URL}/setup-2fa`,
  RESEND_2FA_ENDPOINT: (employeeId: string) =>
    `${BASE_URL}/resend-2fa?employeeId=${employeeId}`,
};

export const REGISTER_ENDPOINT = `${BASE_URL}/register-user`;
export const SET_PASSWORD_ENDPOINT = `${BASE_URL}/set-password`;
export const LOGIN_ENDPOINT = `${BASE_URL}/login`;
export const GET_STUDENTS_ENDPOINT = (school: number) =>
  `${BASE_URL}/api/v1/students?school=${school}`;
export const GET_PATIENT_ENDPOINT = (id: string) =>
  `${BASE_URL}/api/v1/patients/${id}`;

export const GET_PATIENT_INVOICES_ENDPOINT = (id: string) =>
  `${BASE_URL}/api/v1/patient-invoices/${id}`;
export const UPDATE_PATIENT_STATUS_ENDPOINT = `${BASE_URL}/api/v1/update-patient-status`;
export const GET_PATIENTS_IN_HOSPITAL_ENDPOINT = (hospitalId: number) =>
  `${BASE_URL}/api/v1/patients/currently-in-hospital?hospital_id=${hospitalId}`;
export const REGISTER_PATIENT_ENDPOINT = `${BASE_URL}/api/v1/register-patient`;
export const REGISTER_MULTIPLE_USERS_ENDPOINT = `${BASE_URL}/add-multiple-users`;
export const CREATE_PAYMENT_ITEMS = `${BASE_URL}/api/v1/create-payment-item`;
export const GET_ALL_INVOICES = (id: number) =>
  `${BASE_URL}/api/v1/invoices?user_id=${id}`;

export const SCHOOL_ENDPOINT = `${BASE_URL}/api/v1/schools`;
export const STUDENT_DASHBOARD = `${BASE_URL}/api/v1/dashboard/student`;

export const VERIFY_2FA = `${BASE_URL}/verify-2fa`;
export const SETUP_2FA = `${BASE_URL}/setup-2fa`;
export const VERIFY_EMAIL_ENDPOINT = `${BASE_URL}/verify-email`;
export const RESEND_2FA_ENDPOINT = (employeeId: string) =>
  `${BASE_URL}/resend-2fa?employeeId=${employeeId}`;
export const RECORD_VITALS_ENDPOINT = `${BASE_URL}/api/v1/record-vitals`;
