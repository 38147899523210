import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useForm, SubmitHandler } from "react-hook-form";
import { executeCreatePaymentItem } from "apis/students";
import useDisclosure from "hooks/useDisclosure";
import toast from "react-hot-toast";
import { useAppSelector } from "hooks/redux";

interface CreatePaymentItemsFormProps {
  onClose: () => void;
}

interface FormData {
  title: string;
  faculty: string;
  amount: number;
  department: string;
  level: string;
  from: string;
  to: string;
}

const CreatePaymentItemsForm: React.FC<CreatePaymentItemsFormProps> = ({
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const selectedSchool = useAppSelector((state) => state.school.selectedSchool);
  const { onOpen: openLoading } = useDisclosure();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      openLoading();
      const transformedData = {
        name: data.title,
        amount: data.amount,
        school_id: selectedSchool!.id,
        due_date: data.to,
        description: `This is the test ${data.title}`,
        department_id: Number(data.department),
        level: data.level,
      };
      const result = await executeCreatePaymentItem(transformedData);
      if (result.isSuccess === false) throw new Error(result.message);

      toast.success(result.message);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      onClose();
    }
    handleClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="max-w-lg w-full p-6 bg-white shadow-lg rounded-lg relative mt-32">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl">Create New Payment Item</h1>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <IoIosClose className="h-6 w-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-gray-700 font-semibold"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              placeholder="Level Fees"
              {...register("title", { required: "Title is required" })}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {errors.title && (
              <p className="text-red-500">{errors.title.message}</p>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="faculty"
                className="block text-gray-700 font-semibold"
              >
                Faculty
              </label>
              <input
                type="text"
                id="faculty"
                placeholder="Engineering"
                {...register("faculty", { required: "Faculty is required" })}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              {errors.faculty && (
                <p className="text-red-500">{errors.faculty.message}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="amount"
                className="block text-gray-700 font-semibold"
              >
                Amount
              </label>
              <input
                type="number"
                id="amount"
                placeholder="Amount"
                {...register("amount", {
                  required: "Amount is required",
                  valueAsNumber: true,
                })}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              {errors.amount && (
                <p className="text-red-500">{errors.amount.message}</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="department"
                className="block text-gray-700 font-semibold"
              >
                Department
              </label>
              <input
                type="text"
                id="department"
                placeholder="Chemical Engineering"
                {...register("department", {
                  required: "Department is required",
                })}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              {errors.department && (
                <p className="text-red-500">{errors.department.message}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="level"
                className="block text-gray-700 font-semibold"
              >
                Level
              </label>
              <input
                type="text"
                id="level"
                placeholder="300"
                {...register("level", { required: "Level is required" })}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              {errors.level && (
                <p className="text-red-500">{errors.level.message}</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="from"
                className="block text-gray-700 font-semibold"
              >
                From
              </label>
              <input
                type="date"
                id="from"
                {...register("from", { required: "Start date is required" })}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              {errors.from && (
                <p className="text-red-500">{errors.from.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="to" className="block text-gray-700 font-semibold">
                To
              </label>
              <input
                type="date"
                id="to"
                {...register("to", { required: "End date is required" })}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              {errors.to && <p className="text-red-500">{errors.to.message}</p>}
            </div>
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="px-6 py-2 bg-red-500 text-white font-semibold rounded-md shadow-md hover:bg-red-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePaymentItemsForm;
