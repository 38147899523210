import { Input } from "components/Form";
import Modal from "./Modal";
import { useForm } from "react-hook-form";

interface AddPaymentItemProps {
  closeModal: () => void;
  isOpen: boolean;
  paymentItem: any; // replace with real type when provided by API or state
}

const AddPaymentItemModal = ({
  closeModal,
  isOpen,
  paymentItem,
}: AddPaymentItemProps) => {
  const { register } = useForm();

  return (
    <Modal
      closeModal={closeModal}
      isOpen={isOpen}
      title={!!paymentItem.name ? "Edit Payment Item" : "New Payment Item"}
      width={"max-w-3xl"}
    >
      <form className="flex flex-wrap justify-between gap-4">
        <div className="w-full">
          <Input
            className="border-gray-400 text-gray-600"
            color={true}
            label="Title"
            type="text"
            name="title"
            placeholder="Level Fees"
            register={register}
            required={true}
          />
        </div>
        <div className="flex gap-4 w-full">
          <Input
            className="border-gray-400 text-gray-600"
            color={true}
            label="Faculty"
            type="text"
            name="faculty"
            placeholder="Engineering"
            register={register}
            required={true}
          />
          <Input
            className="border-gray-400 text-gray-600"
            color={true}
            label="Amount"
            type="number"
            name="amount"
            placeholder="Amount"
            register={register}
            required={true}
          />
        </div>
        <div className="flex gap-4 w-full">
          <Input
            className="border-gray-400 text-gray-600"
            color={true}
            label="Active From"
            type="date"
            name="from"
            register={register}
            required={true}
          />
          <Input
            className="border-gray-400 text-gray-600"
            color={true}
            label="Active To"
            type="date"
            name="to"
            register={register}
            required={true}
          />
        </div>

        <button
          className="px-8 py-2 bg-[#D33833] text-white rounded-lg"
          type="submit"
        >
          Submit
        </button>
      </form>
    </Modal>
  );
};

export default AddPaymentItemModal;
