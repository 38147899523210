import Layout from "Layout";
import { Link } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import CustomTable from "components/Table/CustomTable";
import paymentData, { paymentItemColumns } from "screens/WebPay/helpers";
import PaymentTableHeader from "components/TableHeaders/PaymentTableHeader";
import { useState } from "react";
import { CiEdit } from "react-icons/ci";
import AddPaymentItemModal from "components/Modals/AddPaymentItemModal";

// interface PaymentItem {
//   id: string;
//   name: string;
//   email: string;
//   description: string;
//   department: string;
//   date: string;
//   amount: string;
//   descriptionId: string;
//   level: string;
//   faculty: string;
// }

function BankChannel() {
  const [showPaymentItemModal, setShowPaymentItemModal] = useState({
    show: false,
    paymentItem: {},
  });

  const openPaymentItemModal = () =>
    setShowPaymentItemModal({ show: true, paymentItem: {} });
  const editPaymentItem = (paymentId: string) => {
    const paymentItem = paymentData.filter((item) => item.id === paymentId)[0];
    if (!!paymentItem) setShowPaymentItemModal({ show: true, paymentItem });
    else console.error("Payment item not found");
  };
  const closePaymentItemModal = () =>
    setShowPaymentItemModal({ show: false, paymentItem: {} });

  const actions = [
    {
      title: "Edit",
      icon: CiEdit,
      onClick: (item: any) => {
        editPaymentItem(item.id);
      },
    },
  ];

  return (
    <Layout>
      <PaymentTableHeader openNewItemModal={openPaymentItemModal} />
      <Link
        to="#"
        onClick={() => openPaymentItemModal()}
        className="w-16 animate-bounce h-16 border border-border z-50 bg-subMain text-white rounded-full flex-colo fixed bottom-8 right-12 button-fb"
      >
        <BiPlus className="text-2xl" />
      </Link>

      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="10"
        data-aos-offset="200"
        className="bg-white my-8 rounded-xl border-[1px] border-border p-5"
      >
        <div className="mt-8 w-full overflow-x-scroll">
          <CustomTable
            data={paymentData}
            columns={paymentItemColumns}
            actions={actions}
          />
        </div>
      </div>

      {showPaymentItemModal && (
        <AddPaymentItemModal
          isOpen={showPaymentItemModal.show}
          closeModal={closePaymentItemModal}
          paymentItem={showPaymentItemModal.paymentItem}
        />
      )}
    </Layout>
  );
}

export default BankChannel;
