import toast from "react-hot-toast";
import { FiEye } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

export const columns = [
  {
    title: "NAME",
    accessor: "name",
  },
  {
    title: "Pay Ref",
    accessor: "ref",
  }, {
    title: "Faculty",
    accessor: "faculty",
  },
   { title: "DEPARTMENT", 
    accessor: "department" 
  },
   {
    title: "Level",
    accessor: "level",
  },
  {
    title: "Amount",
    accessor: "amount",
  },
 {
    title: "Date",
    accessor: "date",
  },
  {
    title: "From",
    accessor: "from",
  },
  {
    title: "To",
    accessor: "to",
  },
].filter(Boolean);

export const actions = (previewPayment: (id: number) => void) => [
  {
    title: "Edit",
    icon: FiEye,
    onClick: (item: any) => {
      previewPayment(item.id);
    },
  },
  {
    title: "Delete",
    icon: RiDeleteBin6Line,
    onClick: () => toast.error("This feature is not available yet"),
  },
];

export const paymentItemsData = [
  {
    name: "Level Fee",
    ref: "ERG4883933",
    faculty: "Social Sciences",
    department: "Political Science",
    level: "200",
    amount: 12000,
    date: "2024-08-17",
    from: "2024-08-17",
    to: "2024-08-17"
  },
  {
    name: "Registration Fee",
    ref: "ERG4883932",
    faculty: "Management Sciences",
    department: "Banking and Finance",
    level: "400",
    amount: 12000,
    date: "2024-08-17",
    from: "2024-08-17",
    to: "2024-08-17"
  },
  {
    name: "Hostel Fee",
    ref: "ERG4883930",
    faculty: "Sciences",
    department: "Medcine and Surgery",
    level: "100",
    amount: 12000,
    date: "2024-08-17",
    from: "2024-08-17",
    to: "2024-08-17"
  },
  {
    name: "Departmental Fee",
    ref: "ERG4883937",
    faculty: "Arts",
    department: "Theatre Arts",
    level: "400",
    amount: 12000,
    date: "2024-08-17",
    from: "2024-08-17",
    to: "2024-08-17"
  },
  {
    name: "Registration Fee",
    ref: "ERG4883938",
    faculty: "Management Sciences",
    department: "Accounting",
    level: "400",
    amount: 12000,
    date: "2024-08-17",
    from: "2024-08-17",
    to: "2024-08-17"
  },
  {
    name: "Hostel Fee",
    ref: "ERG4883932",
    faculty: "Sciences",
    department: "Laboratory Science",
    level: "400",
    amount: 12000,
    date: "2024-08-17",
    from: "2024-08-17",
    to: "2024-08-17" 
  },
  {
    name: "Departmental Fee",
    ref: "ERG4883932",
    faculty: "Applied Sciences",
    department: "Physics",
    level: "500",
    amount: 12000,
    date: "2024-08-17",
    from: "2024-08-17",
    to: "2024-08-17" 
  },
  {
    name: "Level Fee",
    ref: "ERG4883932",
    faculty: "Arts",
    department: "Mass Communication",
    level: "400",
    amount: 12000,
    date: "2024-08-17",
    from: "2024-08-17",
    to: "2024-08-17" 
  },
  {
    name: "Registration Fee",
    ref: "ERG4883932",
    faculty: "Arts",
    department: "Mass Communication",
    level: "400",
    amount: 12000,
    date: "2024-08-17",
    from: "2024-08-17",
    to: "2024-08-17" 
  },
  {
    name: "Level Fee",
    ref: "ERG4883932",
    faculty: "Arts",
    department: "Mass Communication",
    level: "400",
    amount: 12000,
    date: "2024-08-17",
    from: "2024-08-17",
    to: "2024-08-17" 
  },
];

export default paymentItemsData;
