import { FiFilter } from "react-icons/fi";
import { CiUndo } from "react-icons/ci";

const Filters = () => {
  return (
    <div className="flex items-stretch">
      <div className="flex items-center p-1 px-6 border-y border-l border-[#D5D5D5] rounded-l-lg text-sm">
        <FiFilter />
      </div>
      <div className="flex items-center p-1 px-6 border-y border-l border-[#D5D5D5] text-sm">
        Filter By
      </div>
      <div className="flex items-center p-1 px-6 border-y border-l border-[#D5D5D5]">
        <label className="text-sm" htmlFor="description">
          Select Description
        </label>
        <select className="bg-transparent">
          <option value=""></option>
        </select>
      </div>
      <div className="flex items-center p-1 px-6 border rounded-r-lg border-[#D5D5D5]">
        <button className="flex items-center gap-2 text-[#EA0234] text-sm">
          <CiUndo />
          Reset Filter
        </button>
      </div>
    </div>
  );
};

const WebPayTableHeader = () => {
  return (
    <div className="flex justify-between">
      <div className="flex gap-4">
        <Filters />
        <input
          type="search"
          className="border px-3 text-sm border-[#D5D5D5] rounded-3xl"
          placeholder="Search keywords"
        />
      </div>

      <button className="px-4 bg-white border rounded-3xl text-sm text-[#049621]">
        Get CSV
      </button>
    </div>
  );
};

export default WebPayTableHeader;
